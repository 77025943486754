.item-list {
    text-align: center;
    @include clearfix();
    background: #e6e9eb;
    position: relative;
    padding: 20px 15px;
    margin: 30px 0 0;

    .pager {
        display: inline-block;
        position: relative;
        padding: 0;
        margin: 0;

        li {
            font-weight: bold;
            display: inline-block;
            height: 34px;
            line-height: 35px;
            margin: 2px;
            padding: 0;
            text-align: center;
            width: 30px;
            border: 1px solid #c2c7cb;
            border-radius: 4px;
            overflow: hidden;
            @include gradient-vertical(#fff, #eee, 0%, 100%);

            &:hover {
                color: #ffffff;
                background: #003469;
                
                a {
                    color: #ffffff;
                }
            }

            &.first {

            }

            &.last {

            }

            a {
                text-decoration: none;
                display: block;
                width: 30px;
                height: 34px;
                padding: 0;
                color: #000;
            }

            &.pager-previous,
            &.pager-next,
            &.pager-last,
            &.pager-first {
                width: auto;
                font-size: 0;
                a{

                    &:before {
                        @include fontAw(16px, "");
                        display: inline-block;
                        font-weight: normal;
                    }
                }
            }
            &.pager-previous {
                a:before {
                    content: "\f104";

                }
            }
            &.pager-next {
                a:before {
                    content: "\f105";
                }
            }
            &.pager-last {
                a:before {
                    content: "\f101";
                }
            }
            &.pager-first {
                a:before {
                    content: "\f100";
                }
            }
            &.pager-current {
                color: #aaa;
                cursor: default;
                &:hover {
                    cursor: default;
                    color: #aaa;
                    @include gradient-vertical(#fff, #eee, 0%, 100%);
                }
            }
            &.pager-ellipsis {
                border-color: transparent;
                background: transparent!important;
                color: #000;
            }

        }
    }
}