.btn,
a.btn,
a:link.btn {
  display: inline-block;
  padding: 7px 15px;
  text-transform: uppercase;
  background-color: $button-bg-color;
  color: $button-font-color;
  text-decoration: none!important;
  text-align: center;
  border: 1px solid $button-border-color;
  font-size: 1.2em;
  transition: all 300ms;

  &:hover {
    cursor: pointer;
    background-color: $button-bg-hover-color;
    transition: background-color .35s ease;
    color: $button-font-hover-color;

  }
}