/* variables */
$page-background: #3a3a3a;
$blue: #003469;
$font-color: #4b535a;
$link-color: $blue;
$link-hover-color: #084f98;
$menu-link-background-color: #000;
$menu-link-background-hover-color: #cdcdcd;
$menu-link-font-color: #fff;
$menu-link-font-hover-color: #000;
$padding-mobile: 10px;
$h1-color: $blue;
$h2-color: $blue;
$h3-color: $blue;
$h4-color: $blue;
$background-img: url('../img/background.jpg');
$page-width: 1200px;
$hamburger-color: $blue;
$hamburger-background: $blue;
$hamburger-border: none;
$button-firstbg-color: $blue;
$button-secondbg-color: $blue;
$button-font-color: #fff;
$button-font-hover-color: $blue;
$button-bg-color: $blue;
$button-bg-hover-color: #fff;
$button-border-color: $blue;
// media queries boundaries: @media screen and (max-width: $mqSmallDesktopTo){ }

$cbox-halfsize: 440px;
$mqMobile: 320px;
$mqMobileTo: 559px;

$mqBigMobile: 560px;
$mqBigMobileTo: 767px;

$mqTablet: 768px;
$mqTabletTo: 1024px;

$mqSmallDesktop: 1025px;
$mqSmallDesktopTo: 1279px;
$mqBigDesktop: 1280px;
$fonts-heading: 'Roboto', sans-serif;
$fonts: 'Libre Franklin', sans-serif;

/* media queries variables */
$media-mobileTo: "only screen and (max-width: #{$mqMobileTo})";
$media-bigMobile: "only screen and (min-width: #{$mqBigMobile})";
$media-cbox: "only screen and (min-width: #{$cbox-halfsize})";
$media-only-phablet: "only screen and (min-width: #{$mqBigMobile}) and (max-width: #{$mqBigMobileTo})";
$media-tablet: "only screen and (min-width: #{$mqTablet})";
$media-tabletTo: "only screen and (max-width: #{$mqTabletTo})";
$media-only-tablet: "only screen and (min-width: #{$mqTablet}) and (max-width: #{$mqTabletTo})";
$media-desktop: "only screen and (min-width: #{$mqSmallDesktop})";
$media-smallDesktop: "only screen and (min-width: #{$mqSmallDesktop}) and (max-width:#{$mqSmallDesktopTo})";
$media-bigDesktop: "only screen and (min-width: #{$mqBigDesktop})";
$landscape: "only screen and (orientation: landscape)";
$portrait: "only screen and (orientation: portrait)";




