.view-cenik {

    h3 {
        font-size: 1.4em;
        margin-top: 50px; 
    }

    .views-row {
        margin: 0 0 30px 50px;
    }
    .views-field-title {
        h3 {
            margin: 10px 0;
            font-size: 1.17em;
        }
    }
    .views-field-php {
        p {
            margin: 0;
        }
    }
}