.node-type-tour {
	#content {
	    float: none;
	    max-width: 900px;
	    margin: 0 auto;
	}
}

.paragraphs-item-cena {
    padding: 5px 0;
    .field-name-field-paragraph-prize-title {
        float: left;
        padding-right: 1em;
        min-width: 120px;
    }
    .field-name-field-prize {
        font-weight: bold;
    }
}



.page-taxonomy-term {
    
    article {
        padding-bottom: 15px;
        display: block;
        background: #f5f5f5;
        margin: 0 auto 15px;
        max-width: 390px;
    }

}