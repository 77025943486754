//@import 'font-awesome-4.7.0/scss/font-awesome';
//@import 'fontawesome-free-5.13.0-web/scss/fontawesome';
//@import 'fontawesome-free-5.13.0-web/scss/regular';
//@import 'fontawesome-free-5.13.0-web/scss/solid';
//@import 'fontawesome-free-5.13.0-web/scss/brands';
@import 'owl-carousel/owl';
@import 'normalize';
@import 'variables';
@import 'mixins';
// @import 'mixins-google-fonts';
@import 'google-fonts';
@import "mobilmenu";
@import 'header';
@import "administration";
@import "slider";
@import 'page';
// @import "sidebar";
@import "nodequeue";
@import 'footer';
@import 'poptavka';
@import "languages";
@import 'galerie';
@import "tour";
@import 'messages';
@import 'forms';
@import 'buttons';
@import 'stylesheet';
@import 'frontPage';
@import "breadcrumb";
@import "icons";
@import "tripadvisor-certificate";
@import "pager";
@import "cenik";
// @import 'buttons';
// @import 'forms';
// @import 'headers';
// @import 'modals';
// @import 'tabs';
// @import 'tables';
// @import 'checkbox';