html {
    position: relative;
}
body {
    margin: 0;
    background-color: $page-background;
    font-size: 16px;
    font-weight: 400;
    font-family: $fonts;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @media #{$media-bigMobile} {

    }
}
#page {
    padding-bottom: 30px;
}
#bodyIn {
    background: #fff;
    @include clearfix();
}

#container {
    max-width: 1200px;
    margin: 0 auto;
    text-align: left;
    padding: 10px 15px 0;
}

#sidebar {
    @media #{$media-tablet} {
        float: left;
        width: calc(35% - 41px);
        padding: 20px;
        margin: 20px 0;
        border-left: 1px solid #cdcdcd;
    }
}

#content {

}

.node-type-page #content,
.node-type-webform #content,
.node-type-article #content,
.page-node-178 #content {
    float: none;
    max-width: 900px;
    margin: 0 auto;
}