dl {
    dt {
        margin: 1em 0;
    }
}

ul.primary {
    border-collapse: collapse;
    height: auto;
    line-height: normal;
    list-style: none;
    margin: 5px;
    padding: 0 0 0 1em;
    white-space: nowrap;
    border-bottom: 1px solid #666;
    li {
        display: inline-block;
        position: relative;
        top: 5px;
        a {
            text-decoration: none;
            background: none;
            border: none;
            display: block;
            float: left;
            line-height: 1.5385em;
            margin: 0;
            padding: 0 1em;
            border-radius: 4px 4px 0 0;
            margin-right: 5px;
            &:hover, &.active {
                background: #666;
                border: none;
                color: #fff;
            }
        }
    }
}

.logged-in {
    #header {
        padding: 40px 0 0 0;
    }
}

#block-system-user-menu {
    position: absolute;
    left: 0;
    top: -40px;
    width: 100%;
    background: #333;
    padding: 5px;
    height: 30px;

    h2 {
        display: none;
    }

    ul.menu {
        display: block;
        max-width: 1200px;
        margin: 0 auto;
        li {
            display: inline-block;
            a {
                display: block;
                color: white;
                padding: 3px 10px;
            }
        }
    }
}