.messages {
    padding: 5px 20px;
    border-radius: 3px;
    border: 1px solid #cdcdcd;
    background: #f8f8f8;

	&.status {
		border: 1px solid #21a500;
    	background: #d7fbce;
	}
}