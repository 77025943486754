.view-nodequeues, #block-views-about-us-block {
    @include clearfix();
    padding-bottom: 30px;

    h2 {
        text-align: center;
        @media #{$media-tablet} {
            text-align: left;
        }
    }

    .view-content {

        @media #{$media-tablet}{
            display: grid;
//             grid-template-columns: repeat(2, 1fr);
            grid-gap: 20px;
            grid-auto-rows: minmax(100px, auto);
        }

        @media #{$media-tablet}{
            grid-template-columns: repeat(3, 1fr);
        }

        .views-row {
            @include clearfix();
            padding-bottom: 15px;
            display: block;
            background: #f5f5f5;
            margin: 0 auto 15px;
            max-width: 390px;

            @media #{$media-tablet}{
                margin: 0;                
            }
            
            .box-text {
                padding: 5px 10px 0;

                @media #{$media-desktop}{
                    padding: 15px 30px 0;
                }

                h3 {
                    margin: 0;
                    padding: 0.6em 0;
                    @media #{$media-tablet}{
                        padding: 10px 0;
                    }
                }
                p {
                    padding: 0;
                    margin: 0;
                    @media #{$media-tablet}{
                        padding: 5px 0 10px;
                    }
                }
            }
            .alpha {
                a {
                    display: block;
                    img {
                        display: block;
                        width: 100%;
                        height: auto;
                    }
                }
            }
        }

        .views-row-1 {

            @media #{$media-tablet}{
                grid-column: 1;
                grid-row: 1;
            }

        }
        .views-row-2 {

            @media #{$media-tablet}{
                grid-column: 2;
                grid-row: 1;
            }
            
        }
        .views-row-3 {

            
//                 grid-column: 1;
//                 grid-row: 2;
            
            @media #{$media-tablet}{
                grid-column: 3;
                grid-row: 1;
            }
            
        }
    }

    .more-link {
        padding: 30px 0 15px;
    }
}
