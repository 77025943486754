label {
    display: block;
    padding: 7px 0;
}

input {
    width: calc(100% - 24px);
    border-radius: 0;
    padding: 0 12px;
    height: 40px;
    line-height: 40px;
    border: 1px solid #cdcdcd;

    @media #{$media-bigMobile}{
        max-width: 300px;
    }
}

select {
    border-radius: 0;
    height: 40px;
    line-height: 40px;
    padding: 0 10px;
    border: 1px solid #cdcdcd;
    max-width: 100%;

    @media #{$media-bigMobile}{
        max-width: 326px;
    }
    &#edit-submitted-select-tour {
        width: 100%;
    }
}

textarea {
    height: 120px;
    width: calc(100% - 24px);
    border-radius: 3px;
    padding: 0 12px;
    border: 1px solid #cdcdcd;
    @media #{$media-bigMobile}{
        max-width: 300px;
    }
}

.form-item {
    margin: 10px 0;
}

input[type="submit"] {
    width: auto;
    cursor: pointer;
    display: inline-block;
    height: 38px;
    line-height: 38px;
    font-size: 120%;
    margin: 0 0 0 15px;
    padding: 0 15px;
    text-decoration: none;
    background: $button-secondbg-color;
    border: 1px solid $button-secondbg-color;
    border-radius: 0;
    font-weight: 500;
    color: #fff !important;
    // letter-spacing: 2px;
    transition: 300ms all;

    &:first-child {
        margin-left: 0;
    }
    &:hover {
        background: $button-bg-hover-color;
        color: $button-font-hover-color !important;
    }
}

input[type="image"].webform-calendar {
    width: auto;
    height: 40px;
    line-height: normal;
    border: 0;
    padding: 0!important;
}

.form-actions {
    padding: 20px 0 0;
}

#block-contactblock {
    position: relative;
    float: none;
    padding: 10px 40px 40px;

    margin: 20px 0;
    @media #{$media-bigMobile} {
        float: left;
        label {
            float: left;
            min-width: 80px;
            text-align: left;
            padding-right: 20px;
        }
    }
    input[type="text"],
    input[type="email"],
    textarea {
        border-radius: 3px;
        padding: 10px 12px;
        color: #333;
        border: 1px solid #e5e5e5;
    }
    textarea {
        min-height: 126px;
    }

    .form-actions {
        text-align: right;
    }

    #edit-preview {
        display: none;
    }

}

.description {
    font-style: italic;
    font-size: 90%;
}

.webform-component--meeting-point {
    label:after {
        content: "*";
        color: black;
        margin: 0 0 0 5px;
    }
    .description {
        font-style: italic;
        font-size: 90%;
        &:before {
        content: "*";
        color: black;
        margin: 0 5px 0 0;
    }
    }
}

// form-30 rezervace CS
// form-20 rezervace EN

#webform-client-form-30,
#webform-client-form-20 {
    @include clearfix;

    @media #{$media-bigMobile} {

        .webform-component--name,
        .webform-component--surname,
        .webform-component--date,
        .webform-component--meeting-time {
            float: left;
            width: 50%;
            input {
                max-width: inherit;
            }
        }

        .webform-component--select-tour {
            select {
                max-width: inherit;
            }
        }
        .webform-component--e-mail {
            input {
                max-width: inherit;
            }
        }
        .webform-component--meeting-point {
            textarea {
                max-width: inherit;
            }

        }
        .webform-component--name input {
            width: calc(100% - 38px);
        }

        .webform-component--e-mail input {
            width: calc(100% - 24px);
            max-width: inherit;
        }

        .captcha {
            float: left;
            padding: 10px 0;
        }

        .form-actions {
            padding: 10px 0;
            float: right;
        }

    }
}