.front {
    
    h1 {
        text-align: center;
        @media #{$media-tablet} {
            text-align: left;
            padding: 60px 315px 0 0;
        }
    }

    #block-system-main {
        @include clearfix();
        position: relative;
        text-align: center;

        @media #{$media-tablet} {
            text-align: left;
            padding: 10px 315px 0 0;
            min-height: 200px;
        }

        &:before {
            content: "";
            width: 250px;
            height: 250px;
            border-radius: 50%;
            background: url(../img/eva-bartuskova.jpg) no-repeat 0 0;
            @media #{$media-tablet} {
                position: absolute; 
                top: -50px;
                right: 0;
            }
        }
    }

    &.node-type-page #content {
        max-width: inherit;
    }
}
