#block-block-7 {
    @icnlude clearfix;
    text-align: center;
    margin:  30px 0;

    a {

        display: inline-block;
        border: 2px solid #00a680;
        padding: 60px 30px 20px 30px;
        background: #00a680 url(TA_logo_secondary.png);
        background-image: url(../img/TA_logo_secondary.svg), none;
        background-repeat: no-repeat;
        background-size: 200px;
        background-position: center 20px;
        color: white;
        text-decoration: none;
        font-size: 18px;
        line-height: 22px;
        text-align: center;

        @media #{$media-bigMobile} {
            padding: 20px 20px 20px 230px;
            background-position: 20px 20px;
            line-height: 32px;
            height: 30px;
        }

        &:hover {
            background: white url(TA_logo_primary.png);
            background-image: url(../img/TA_logo_primary.svg), none;
            background-repeat: no-repeat;
            background-size: 200px;
            background-position: center 20px;
            color: black;
            @media #{$media-bigMobile} {
                background-position: 20px 20px;
            }
        }
    }
}