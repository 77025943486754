.i18n-en {
    .region-nav {
        .menu {
            li.cs, li.es {
                display: none;
            }
        }
    }
}

.i18n-es {
    .region-nav {
        .menu {
            li.cs, li.en {
                display: none;
            }
        }
    }
}

.i18n-cs {
    .region-nav {
        .menu {
            li.en, li.es {
                display: none;
            }
        }
    }
}

#block-locale-language {
    z-index: 50;
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 30px);
    background: #003469;
    text-align: right;
    padding: 10px 15px 5px;

    @media #{$media-desktop} {
        top: 10px;
        right: 15px;
        left: auto;
        width: auto;
        background: transparent;
        padding: 0;
        height: auto;
        text-align: right;
    }
    h2 {
        color: white;
        position: relative;
        font-size: 14px;
        margin: 8px 10px 0;
        display: inline-block;
        @media #{$media-desktop} {
            display: none;
        }
    }
    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        display: inline-block;
        vertical-align: top;

        @include clearfix();
        li {
            margin-right: 5px;
            display: inline-block;

            &:last-child {
                margin-right: 0;
            }
            a, span {
                display: block;
                position: relative;
                width: 35px;
                height: 35px;
                overflow: hidden;
                border-radius: 50%;
                @media #{$media-desktop} {
                    width: 25px;
                    height: 25px;
                }

                &.locale-untranslated {
                    display: none;
                }

                svg {
                    width: 37px;
                    height: auto;
                    display: block;
                    position: relative;
                    left: -1px;
                    top: -1px;
                    @media #{$media-desktop} {
                        width: 27px;
                    }
                }
                &.active, &:hover {
                    &:before {
                        opacity: 0;
                    }

                }
                &:before {
                    content: "";
                    z-index: 1;
                    position: absolute;
                    left: -1px;
                    top: -1px;
                    width: 37px;
                    height: 37px;
                    display: block;
                    background: #333;
                    opacity: 0.4;
                    transition: 300ms;
                    @media #{$media-desktop} {
                        width: 27px;
                        height: 27px;
                    }
                }
            }
        }
    }
}