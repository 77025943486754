.colorbox-insert-image img {
    margin-right: 8px;
}

/*
.cboxElement img, .colorbox img {
  display: block;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  width: auto;
  margin: auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.cboxElement, .colorbox {
  display: block;
  width: 100%;
  overflow: hidden;
  position: relative;
  height: 0;
  padding-top: 75%;
  border: 1px solid #ccc;
}
.cbox-parent {
  text-align: center;
}
.cbox-items {
  display: inline-block;
  width: 100%;
  padding: 0;
  max-width: 220px;
  max-height: 165px;
  background-color: #fff;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 10px;
}
*/

.field-type-image {
    .field-items {
        //display: grid;
        //grid-template-columns: repeat(auto-fit, minmax(110px, 1fr));
        //grid-gap: 5px;
        text-align: center;
        position: relative;
        @include clearfix;
        padding: 0;
        margin: 0 0 0 -2px;
        text-align: center;
        position: relative;
        width: calc(100% + 4px);

        .field-item {
          display: block;
          float: left;
          margin: 3px;
          width: 123px;
          img {
              display: block;
              width: 100%;
              max-width: 100%;
              height: auto;
          }

        }
        //end .field-item
    }
}