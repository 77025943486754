
#header {
    padding: 0;
    background: white;
    border-bottom: 1px solid #ccc;
    position: relative;
    box-shadow: 0 1px 5px rgba(128, 128, 128, 0.37);
    @include clearfix();
    @media #{$media-desktop} {
        &:before {
            position: absolute;
            z-index: 0;
            bottom: 0;
            right: 0;
            left: 50%;
            top: 0;
            margin-left: -50px;
            content: "";
            background-color: $blue;
        }
    }
    #headerIn {
        @include clearfix();
        width: calc(100% - 30px);
        max-width: 1200px;
        padding: 70px 15px 0;
        margin: 0 auto;
        position: relative;

        @media #{$media-desktop} {
            padding: 15px;
        }

        &:before {

            @media #{$media-desktop} {
                left: 21%;
                border-left: 140px solid transparent;
                border-bottom: 140px solid #003469;
                content: "";
                display: inline-block;
                position: absolute;
                width: 234px;
                top: -25px;
                @-moz-document url-prefix() {
                    top: -24px;
                }
            }


        }
    
    }

    #logo {
        margin: 0 0 20px;
        padding: 0;
        float: left;
        width: 190px;
        height: 74px;
        font-size: 0;
        position: relative;
        left: 0;
        top: 0;
        overflow: hidden;
        @media #{$media-desktop} {
            margin: 0;
        }
        a {
            display: inline-block;
        }
    }

    .region-header {
        .title--h2 {
            border-radius: 3px;
            border: 1px solid white;
            padding: 10px 15px;
            color: white;
            font-size: 15px;
            transition: 500ms ease;
            &:hover {
                color: $blue;
                background: white;
                cursor: pointer;
                transition: width .3s ease, background-color .3s ease;
            }
        }
    }
}

.region-nav {
    position: relative;
    text-align: left;
    z-index: 2;
    clear: left;
    @media #{$media-desktop} {
        width: auto;
        float: right;
        right: 0;
        padding: 45px 0 0;
        clear: none;
    }

    h2 {
        display: none;
    }
    ul {
        display: none;
        position: relative;
        margin: 0 0 20px;
        padding: 0;
        line-height: inherit;
        background: $blue;
        @include clearfix();
        @media #{$media-desktop} {
            display: block;
            background: none;
            margin: 0;
        }
        li {
            list-style: none;
            height: auto;
            padding: 0;
            position: relative;
            border-bottom: 1px solid #cdcdcd;
            @media #{$media-desktop} {
                float: left;
                border: none;
                margin-left: 3px;
            }
            &:last-child {
                border: none;

                @media #{$media-desktop} {
                    margin-left: 20px;

                    a {
                        border: 1px solid #fff;
                        &:hover {
                            background: #fff;
                            color: $blue;
                        }
                        &:after {
                            display: none;
                        }
                    }
                }
            }
            a {
                display: block;
                position: relative;
                text-decoration: none;
                color: white;
                transition: 500ms ease;
                padding: 10px 8px;
                white-space: nowrap;
                font-size: 15px;
//                @media #{$media-smallDesktop} {
//                    font-size: 12px;
//                    padding: 10px 5px;
//                }
                @media #{$media-desktop} {
                    &:after {
                        content: '';
                        display: block;
                        height: 3px;
                        width: 0;
                        background: transparent;
                        transition: width .3s ease, background-color .3s ease;
                        position: absolute;
                        left: 0;
                        bottom: 0;
                    }

                    &:hover,
                    &:focus,
                    &.active {
                        color: white;
                    }
                    &:hover:after {
                        width: 100%;
                        background: #1484ce;
                    }
                    &.active:after {
                        width: 100%;
                        background: white;
                    }
                }
            }
            .submenu-wrap {
                display: none;
                position: absolute;
                background: #fff;
            }
            .dropdown-menu {
                position: relative;
                display: block;
                li {
                    display: inline-block;
                    float: none;
                    margin: 0;
                    a {
                        font-weight: bold;
                        color: $blue;
                    }
                    .dropdown-menu {
                        display: none;
                        position: relative;
                        border: none;
                        li {
                            float: none;
                            display: block;
                        }
                        a {
                            font-size: 90%;
                            color: #666;
                        }
                    }
                }

            }
            @media #{$media-desktop} {
                &:hover .submenu-wrap {
                    display: inline-block;
                }
            }
        }
    }
}

#breadcrumbs {
    h2 {
        display: none;
    }
}

.dev #header {
    border-bottom: 5px solid red;
}

#block-block-16 {
    text-align: center;
    position: relative;
    padding: 1px;
    font-size: 16px;

    @media #{$media-bigMobile} {
        position: absolute;
        font-size: 20px;
        left: 210px;
        top: 75px;
        right: 100px;
    }

    @media #{$media-desktop} {
        position: absolute;
        right: 134px;
        font-weight: bold;
        top: -8px;
        color: white;
        text-align: right;
        font-size: 31px;
    }
}