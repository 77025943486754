#node-16 {
    position: fixed;
    background: #fff;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    padding: 60px 0 0;
    z-index: 999;
    form {
        position: relative;
        .webform-component--close {
            position: absolute;
            right: 0;
            top: -50px;
        }
        #close {
            color: $blue;
            font-size: 3em;
            padding: 0;
            display: inline-block;
            cursor: pointer;
        }
        max-width: 1200px;
        margin: 0 auto;
        label {
            display: inline-block;
            width: 150px;
        }
        input {
            height: 40px;
            line-height: 40px;
            padding: 0 5px;
            width: calc(35% - 10px);
        }
        select {
            height: 45px;
            padding: 0 5px;
            width: 35%;
        }
        input[type='submit'] {
            background: #fff;
            color: $blue;
            border: 2px solid $blue;
            width: 15%;
            line-height: normal;
            font-weight: 600;
            transition: 300ms;
            &:hover {
                background: $blue;
                color: #fff;
            }
        }
        input[type='file'] {
            width: 230px!important;
        }
        .form-textarea-wrapper {
            padding-left: 160px;
            textarea {
                padding: 5px;
                width: calc(40% - 10px);
            }
        }
    }
    .title--h2 {
        a {
            text-decoration: none;
            font-size: 2.5em;
            color: $blue;
        }
    }
}