h1 {
  padding: 30px 0 0;
  margin: 0 0 20px;
  color: $h1-color;
  font-size: 2.2em;
  line-height: 1em;
  font-family: $fonts-heading;
}
h2 {
  color: $h2-color;
  font-family: $fonts-heading;
}
h3 {
  color: $h3-color;
  font-family: $fonts-heading;
  a:link {
    color: $blue;
    text-decoration: none;
  }
}
h4 {
  color: $h4-color;
  font-family: $fonts-heading;
}
h5 {
  font-family: $fonts-heading;
}
h6 {
  font-family: $fonts-heading;
}

a, a:link {
  color: $link-color;
  text-decoration: underline;
}
a:hover {
  color: $link-hover-color;
  text-decoration: none;
}

p {
  line-height: 24px;
}

.wrapper {
  max-width: $page-width;
  margin: 0 auto;
  position: relative;
  padding: 0 15px;
  width: calc(100% - 30px);
}

#slider {
}
  .table-row {
  padding: 15px 0;
  border-bottom: 1px solid #f1ebbc;
  @media #{$media-desktop}{
    display: table-row;
  }
}

.table-cell {
  display: block;
  @media #{$media-desktop}{
    display: table-cell;
    padding: 0 20px;
  }
  h2.not-main {
    margin: 5px 0;
    color: $h2-color;
    padding: 0;
    font-size: 1.5em;
    @media #{$media-desktop} {
      font-size: 1.2em;
    }
  }
  p {
    margin: 5px 0;
  }
  &.buttons {
    padding: 5px 0 0;
  }
}
.element-invisible, span.ext {
  display: none!important;
}
@include iframeFix();

.teaser-readmore, .more-link {
  clear: left;
  text-align: center;
  padding: 15px 0;
  a {
    background: $blue;
    color: #fff;
    text-decoration: none;
    padding: 7px 15px;
    font-size: 1.2em;
    transition: all 300ms;
    border: 1px solid $blue;
    display: inline-block;
    &:hover {
      background: #fff;
      color: $blue;
    }
  }
}
#block-block-1 {
}