#menu-btn {
    display: inline-block;
    position: absolute;
    overflow: hidden;
    top: 92px;
    right: 10px;
    height: 34px;
    width: 40px;
    color: $hamburger-color;
    background: #fff;
    text-align: center;
    line-height: 34px;
    box-shadow: none;
    border-radius: 0;
    border: none;
    cursor: pointer;
    padding: 0 45px 0 5px;
    transition: background 0.3s;
    critical: this;
    &:focus {
        outline: none;
    }

    span {
        display: block;
        position: absolute;
        top: 15px;
        right: 5px;
        width: 30px;
        height: 4px;
        border-radius: 1px;
        background: $hamburger-background;
        transition: background 0s 0.3s;
        &:before,
        &:after {
            position: absolute;
            display: block;
            left: 0;
            width: 100%;
            height: 4px;
            border-radius: 1px;
            background-color: $hamburger-background;
            content: "";
            transition-duration: 0.3s, 0.3s;
            transition-delay: 0.3s, 0s;
        }
        &:before {
            top: -9px;
            transition-property: top, transform;
        }

        &:after {
            bottom: -9px;
            transition-property: bottom, transform;
        }
        critical: this;
    }

    &.is-active span {
        background: none;
        &:before,
        &:after {
            transition-delay: 0s, 0.3s;
        }
        &:before {
            top: 0;
            transform: rotate(45deg);
        }
        &:after {
            bottom: 0;
            transform: rotate(-45deg);
        }
    }

    @media #{$media-desktop}{
        display: none;
        critical: this;
    }

}